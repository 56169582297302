import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import logo from '../../images/site_logo/site_logo_2.svg'
import logo2 from '../../images/site_logo/site_logo_3.svg'
import MobileMenu from '../MobileMenu/MobileMenu'
import { useTranslation } from 'react-i18next';

const Header2 = (props) => {

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
    };

    const [mobailActive, setMobailState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isSticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <header className="site_header site_header_2">
            <div className={`header_bottom stricky  ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-2 col-5">
                            <div className="site_logo">
                                <Link onClick={ClickHandler} className="site_link" to="/">
                                    <img src={logo} alt="Site Logo – Techco – IT Solutions & Technology, Business Consulting, Software Company Site Template" />
                                    <img src={logo2} alt="Site Logo – Techco – IT Solutions & Technology, Business Consulting, Software Company Site Template" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-2">
                            <nav className="main_menu navbar navbar-expand-lg">
                                <div className="main_menu_inner collapse navbar-collapse justify-content-lg-center" id="main_menu_dropdown">
                                    <ul className="main_menu_list unordered_list justify-content-center">
                                        <li><Link onClick={ClickHandler} to="/service">{t('HEADER.SERVICES')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/about">{t('HEADER.PORTFOLIO')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">{t('HEADER.TEAM')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/about">{t('HEADER.ABOUT_US')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">{t('HEADER.CONTACT')}</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-5">
                            <ul className="header_btns_group unordered_list justify-content-end">
                                <li>
                                    <button className="mobile_menu_btn" onClick={() => setMobailState(!mobailActive)} type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="far fa-bars"></i>
                                    </button>
                                </li>
                                <li>
                                    <button className="btn btn-primary" onClick={() => changeLanguage('sr')}>
                                        <span className="btn_label" data-text="SR">SR</span>
                                        <span className="btn_icon">
                                            <i className="fa fa-language"></i>
                                        </span>
                                    </button>
                                </li>
                                <li>
                                    <button className="btn btn-primary" onClick={() => changeLanguage('en')}>
                                        <span className="btn_label" data-text="EN">EN</span>
                                        <span className="btn_icon">
                                            <i className="fa fa-language"></i>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobail-wrap">
                    <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
                        <div className="xb-header-menu-scroll">
                            <div className="xb-menu-close xb-hide-xl xb-close" onClick={() => setMobailState(!mobailActive)}></div>
                            <nav className="xb-header-nav">
                                <MobileMenu />
                            </nav>
                        </div>
                    </div>
                    <div className="xb-header-menu-backdrop" onClick={() => setMobailState(false)}></div>
                </div>
            </div>
        </header>

    )
}

export default Header2;